/* Selector Grid */
.selector-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Dos columnas en pantallas grandes */
  gap: 1rem;
  /* Espacio entre los filtros */
}

/* Pantallas más pequeñas */
@media (max-width: 768px) {
  .selector-grid {
    grid-template-columns: 1fr;
    /* Una columna en pantallas pequeñas */
  }
}

/* Estilo del botón de reset */
.button_filters {
  background-color: #475e68;
  color: #fff;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.button_filters:hover {
  background-color: #fff;
}

button_filters {
  display: block;
  width: 100px;
  height: 50px;
  padding: 8px;
  background-color: #475e68;
  color: #fff;
  border: 2px solid #000000;
  border-radius: 8px;
  cursor: pointer;
}

.show-pet {
  background-color: #e9e9e9;
}

.custom-card .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #80aba4;
  padding: 20px;
}

.custom-card .card-body {
  background-color: #e9e9e9;
  padding: 10px;
}

.custom-card img {
  width: 350px;
  height: 350px;
}

.text-lost {
  font-size: 18px;
  font-weight: lighter;
  font-family: "MedievalSharp", cursive;
}

.card-lost {
  font-size: 19px;
  font-family: "MedievalSharp", cursive;
  font-weight: bold;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}


.container-animation {
  display: grid;
  place-content: center;
  height: 100vh;
  background-color: #fff;
}
.cargando {
  font-weight: 800;
  width: 120px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 auto;
  color: #000000;
}
.texto-cargando {
  padding-top: 20px;
}
.cargando span {
  font-size: 20px;
  text-transform: uppercase;
}
.pelotas {
  width: 30px;
  height: 30px;
  background-color: #000000;
  animation: salto 0.5s alternate infinite;
  border-radius: 50%;
}
.pelotas:nth-child(2) {
  animation-delay: 0.18s;
}
.pelotas:nth-child(3) {
  animation-delay: 0.37s;
}
@keyframes salto {
  from {
    transform: scaleX(1.25);
  }
  to {
    transform: translateY(-50px) scaleX(1);
  }
}

.searching {
  padding: 8px;
  border: 1px solid #6a6a6a;
  border-radius: 4px;
}

.section-gender{
  width: 90px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
}

.section-specie{
  width: 90px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
}

.section-filters{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media (max-width:768px) {
  .selector-section{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .searching{
    width: 100%;
  }

  .section-gender{
    width: 150px;
    height: 40px;
  }

  .section-specie{
    width: 150px;
    height: 40px;
  }

  .section-filters{
    width: 100%;
    height: auto;
  }
}