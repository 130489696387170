/* General Styles */
.container {
    background-color: #f8f9fa;
}

h1,
h5 {
    color: #333;
}

.carousel-inner img {
    border-radius: 0.5rem;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.alert {
    margin-bottom: 1rem;
}

.comment-section {
    margin-top: 2rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .carousel-inner img {
        max-height: 300px;
        object-fit: cover;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 1rem;
    }

    .row {
        flex-direction: column;
    }

    .carousel-inner img {
        height: 200px;
        object-fit: cover;
    }
}