/* Estilos generales */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.form-section-rf {
  background-color: #f8f9fa;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  box-sizing: border-box;
}

.btn-toggle-form-rf {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.form-container-rf {
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  max-height: 0;
  opacity: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container-rf.open {
  max-height: 1000px;
  opacity: 1;
}

.form-rf {
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group-rf {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.form-group-rf label {
  margin-bottom: 0.5rem;
}

.form-rf input,
.form-rf textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-rf textarea {
  min-height: 150px;
}

.form-rf button {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  width: 100%;
}

.alert-rf {
  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}

.alert-success-rf {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.alert-danger-rf {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.text-center-rf {
  text-align: center;
}

@media (max-width: 768px) {
  .form-rf {
    padding: 1rem; 
  }

  .form-rf button {
    font-size: 1rem; 
  }

  .form-rf textarea {
    min-height: 100px;
  }
  
}
