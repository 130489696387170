
.form-section {
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .form-section h2 {
    font-size: 2rem;
  }
  
  .image-preview {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .image-preview-container {
    position: relative;
  }
  
  .image-preview-container .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  