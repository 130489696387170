.adopcion{
  background-image: url('../imagenes/bg-adopcion.png');
  background-color: #B7AA88;
  background-repeat: no-repeat;
  background-position: center bottom;
  color: white;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding-top: 10vw; 
  padding-bottom: 10vw; 
}
.pet-adopcion{
  background-color: #817566;
  max-width: 100%;
}

.feature-card {
border-radius: 10px; 
padding: 20px;
}


.feature-card h3 {
margin-bottom: 10px;
}

.adopcion h1 {
  font-size: 6vw;
  font-weight: bold;
  margin-top: -3vw; 
}

.Person{
  max-width: 40px;
}

@media (max-width: 767px) {
  .adopcion h1 {
    font-size: 10vw; 
    margin-top: -3vw;
  }

  .Person{
    max-width: 40px;
  }

  .adopcion{
    background-size: 400px;
    max-width: 500px;
  }

  .InfoPet{
    width: 400px;
  }
}

