.container_reportar_mascotas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 10px;
}
.section_background {
    height: 100vh;
    width: 100%;
    background-color: #678695;
}

.form_custom h1 {
    margin-top: 3rem;
    color: #000000;
    text-align: center;
}

.form_input_container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.form_input_container_row {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    gap: 1rem;
}

.form_label_custom {
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
}

.form_input_custom_animation_scale {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #5b86a0;
    transition: border 0.3s ease, transform 0.3s ease;
}

.form_input_custom_animation_scale:focus {
    outline: none;
    border: 3px solid #546c78;
    transform: scale(1.05);
}

/* Input Checkbox */
.form_input_checkbox_custom {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.form_input_checkbox_custom_label {
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
}

.container_checkbox_custom {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.form_input_textarea_custom {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #5b86a0;
    transition: all 0.20s ease-in-out;
}

.form_input_textarea_custom:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(84, 108, 120, 0.25);
}

.form_input_select_custom {
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #5b86a0;
    transition: all 0.20s ease-in-out;
}

.form_input_select_custom:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(84, 108, 120, 0.25);
}

.form_input_custom {
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #5b86a0;
    transition: all 0.20s ease-in-out;
}

.form_input_custom:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(84, 108, 120, 0.25);
}

.grid_column_custom {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
}

.form_group_column_custom {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
}

.grid_col-1 {
    grid-column: span 1;
}

.grid_col-2 {
    grid-column: span 2;
}

.grid_col-3 {
    grid-column: span 3;
}

.grid_col-4 {
    grid-column: span 4;
}

.grid_col-5 {
    grid-column: span 5;
}

.grid_col-6 {
    grid-column: span 6;
}

.grid_col-7 {
    grid-column: span 7;
}

.grid_col-8 {
    grid-column: span 8;
}

.grid_col-9 {
    grid-column: span 9;
}

.grid_col-10 {
    grid-column: span 10;
}

.grid_col-11 {
    grid-column: span 11;
}

.grid_col-12 {
    grid-column: span 12;
}

/* Estilos de botones */

.button_grid_custom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-gap: 10px; */
    width: 13%;
    justify-content: center;
    align-items: center;
}

.button_grid_custom:hover {
    cursor: pointer;
    background-color: #89b0c4;
    color: #000000;
}

.button_custom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 4rem;
    transition: all 0.3s ease-in-out;
}

.button_custom:hover {
    cursor: pointer;
    background-color: #89b0c4;
    color: #000000;
}

.svg_custom svg {
    margin: auto;
    /*margin-left: 0.2rem;*/
}


/* Estilos genericos */
.padding-top {
    padding-top: 2rem;
}

.margin-top {
    margin-top: 0.6rem;
}

/* Display none cuando tenga cierta medida la pantalla */
@media (min-width: 768px) and (max-width: 1023px) {
    .md_none {
        display: none;
    }
    .md_container_one {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 767px) {
    .sm_none {
        display: none;
    }
    .md_container_one {
        grid-template-columns: 1fr;
    }
    .md_form_group_column_custom {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
    }
}