
.container-app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
} 

.container-main {
    margin-bottom: 50px;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Hace que el contenedor ocupe toda la altura de la ventana */
  background-color: #f8f9fa; /* Color de fondo claro */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3; /* Color de fondo del borde */
  border-top: 6px solid #007bff; /* Color del borde superior */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Animación de giro */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #007bff; /* Color del texto */
  text-align: center;
}

/* Responsivo */
@media (max-width: 768px) {
  .spinner {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }

  .loading-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .spinner {
    width: 30px;
    height: 30px;
    border-width: 3px;
  }

  .loading-text {
    font-size: 0.9rem;
  }
}
