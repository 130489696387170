
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Estilos para sección hero */
.hero-section {
  background-color: #678695;
  background-size: cover;
  background-position: center;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
}

.hero-content {
  padding: 1rem;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.btn-sp {
  padding: 12px;
  font-size: 1rem;
  background-color: #546c78;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-sp:hover {
  background-color: #85a09e;
}

.form-rf {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group-rf {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.form-group-rf label {
  margin-bottom: 0.5rem;
}

.form-rf textarea {
  min-height: 150px;
}

.form-rf button {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.alert-rf {
  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}

.alert-success-rf {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.alert-danger-rf {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.text-center-rf {
  text-align: center;
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
  }

  .btn-sp {
    padding: 10px;
    font-size: 0.9rem;
    width: 120px;
    height: 40px;
  }


  .form-container-rf {
    max-width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 0rem;
  }
  .form-rf {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-group-rf {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
  }
}
