/* textarea */
.input_textarea_comment {
  width: 100%; 
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.input_textarea_comment:focus {
  outline: none;
}

.btn_comment {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn_comment_login {
  padding: 0.5rem 1rem;
    /* Ajusta el padding según sea necesario */
    font-size: 1rem;
    /* Ajusta el tamaño de la fuente */
    display: inline-block;
    /* Asegura que el botón se ajuste al contenido */
    border: none;
    /* Opcional: quita el borde predeterminado */
    background-color: #007bff;
    /* Ejemplo de color de fondo */
    color: white;
    /* Ejemplo de color de texto */
    cursor: pointer;
    /* Cambia el cursor al pasar sobre el botón */
}

.btn_comment:hover {
  background-color: #45a049;
  color: white;
}