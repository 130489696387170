.hero-title {
    padding: 20px;
    background-color: #678695;
    color: white;
    text-align: center;
    font-display: 2;
}

.contenido {
    margin-bottom: 50px; 
  }

  .container{
    height: auto;
    margin-top: 15px;
  }