/* cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
} */

.cropContainer {
    position: relative;
    width: 100%;
    height: 200px;
    background: #333;
}

