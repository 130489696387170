
.footer-container {
    width: 100%;
    background-color: white;
    color: black ;
    padding: 10px 0;
    position: fixed; 
    bottom: 0; 
    left: 0;
  }
  
  .footer {
    width: 100%;
    text-align: center;
  }
  
  .footer p {
    margin: 0; 
  }