.cuidados{
  background-image: url('../imagenes/cuidados.png');
  background-color: #5985A0;
  background-repeat: no-repeat;
  background-position: center bottom;
  color: white;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding-top: 10vw; 
  padding-bottom: 10vw; 
}
.pet-cuidados{
  background-color: #979696;
}

.custom-content {
  padding: 20px; 
}

.cuidados h1 {
  font-size: 6vw;
  font-weight: bold;
  margin-top: -3vw; 
}

@media (max-width: 767px) {
  .cuidados h1 {
    font-size: 10vw; 
    margin-top: -3vw;
  }

  .cuidados{
   background-size: 440px;
  }

}

