.register-container{
  display: flex;
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.logo-section{
  text-align: center;
  margin-bottom: 20px;
}

.logoPet{
  width: 100px;
  height: auto;
}


.img-container{
  height: 100%;
  width: auto;
}

.form-group{
  margin-bottom: 20px;

}

.form-control{
  width: 250px;
  padding: 10px;
  margin-top: 5px;
  /* border: 1px solid white; */
  /* border-radius: 4px; */
}

.imgFromSign{
  width: 90%;
  height: 720px;
}

.register-img{
  max-width: 600px;
  height: auto;
}

.signup-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.input-group .form-group-register{
  flex: 1;
  margin-right: 10px;
}

.input-group .form-group-register:last-child {
  margin-right: 0;
}

.button-container{
  display: flex;
  justify-content: center;
  align-items: center;

}

.buttonSing{
  width: 250px;
  background: #D2E3C8;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.buttonSing:hover{
  background-color: #a7bb95;
  color: black;
}

.show-password {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
}

.icon-show-password {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 55%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media(max-width: 1280px){
  .icon-show-password {
    position: relative;
    top: -33px;
    right: -25%;
    transform: translateY(0);
  }
  .register-container{
    flex-direction: column;
  }

  .img-container{
    width: 0px;
    height: auto;
    padding: 20px;
  }

  .form-container{
    width: 100px;
  }

  .form-group{
    text-align: center;
    width: 500px;
  }

  .form-control{
    max-width:300px;
    padding: 10px;
    margin-top: 5px;
    /* border: 1px solid white; */
    border-radius: 4px;
    max-width: 300px;
    padding: 10px;
    margin-top: 5px;
    /* border: 1px solid white; */
    border-radius: 4px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }


  .input-group .form-group{
    width: 100%;
  }

  .buttonSing{
    width: 200px;
  }

  .register-img{
    max-width: 0px;
    height: 0;
  }

}