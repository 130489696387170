.section1 {
  background-color: #f1e4c3;
}

.section2 {
  background-color: #d2e3c8;
}

.section3 {
  background-color: #abc7d8;
}

.container-main-index {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.main-content {
  background-color: #b1925c;
  display: grid;
  grid-template-rows: 2fr 1fr 10fr;
}

.main-image-container {
  display: grid;
  align-items: end;
  justify-content: center;
}

.main-image {
  width: 75%;
  height: auto;
}

.main-title {
  text-align: center;
  display: grid;
  align-items: center;
}

.main-title-font {
  font-size: 3em;
  font-weight: bold;
  color: black;
}

.main-link {
  display: grid;
  align-items: center;
  justify-content: center;
}

.main-link a {
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 5px;
}

.main-link a:hover {
  background-color: #333;
}

.info-sections {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.icon {
  width: 200px;
  height: 200px;
}

.info-section {
  display: grid;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {

  .container-main-index {
    display: flex;
    flex-direction: column;
    margin-bottom: 275px;
  }

  .main-content {
    display: flex;
    flex-direction: column;
  }

  .main-image-container {
    display: flex;
    justify-content: center;
  }
  
  .main-title-font {
    font-size: 2em;
    margin-top: 2rem;
  }

  .info-sections {
    display: flex;
    flex-direction: column;
  }



}